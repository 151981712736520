<template>
  <div>
    <h1>{{ $t('views.contact.h1_title') }}</h1>
    <h2>{{ $t('views.contact.h2_new') }}</h2>
    <p>{{ $t('views.contact.p_new') }}</p>
    <div v-if="messageSent">
      <v-alert dense text color="success">
        <template #prepend>
          <fa-icon :icon="['far', 'check']" size="2x" class="ma-2 mr-4" />
        </template>
        <p>{{ $t('views.contact.p_sent') }}</p>
        <p class="mb-0">{{ $t('views.contact.p_answer') }}</p>
      </v-alert>
      <v-row class="my-0">
        <v-col class="text-center">
          <btn-home></btn-home>
        </v-col>
      </v-row>
    </div>
    <form v-else novalidate @submit.prevent="submit()">
      <v-row class="my-0">
        <v-col cols="12" lg="6">
          <v-text-field
            v-model="name"
            :disabled="disableFormElements"
            :error-messages="nameErrors"
            color="teal"
            :label="$t('views.contact.label_name')"
            @input="nameInput()"
            @blur="nameInput()"
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="6">
          <v-text-field
            v-model="email"
            :disabled="disableFormElements"
            :error-messages="emailErrors"
            color="teal"
            :label="$t('views.contact.label_e-mail_address')"
            @input="emailInput()"
            @blur="emailInput()"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="my-0">
        <v-col>
          <v-text-field
            v-model="subject"
            :disabled="disableFormElements"
            :error-messages="subjectErrors"
            color="teal"
            :label="$t('views.contact.label_subject')"
            @input="subjectInput()"
            @blur="subjectInput()"
          ></v-text-field>
          <v-textarea
            v-model="message"
            :disabled="disableFormElements"
            :error-messages="messageErrors"
            color="teal"
            :label="$t('views.contact.label_message')"
            rows="10"
            @input="messageInput()"
            @blur="messageInput()"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row v-if="!messageSent" class="my-0">
        <v-col class="text-center">
          <v-btn :disabled="disableFormElements" color="$theme.btn.color" outlined rounded type="submit">
            <fa-icon class="mr-2" size="lg" :icon="['fal', 'paper-plane']" />{{ $t('views.contact.btn_send') }}
          </v-btn>
        </v-col>
      </v-row>
    </form>
    <div class="text-center my-3">
      <Adslot ad-unit="content-last" ad-class="adslot__content-last" />
    </div>
  </div>
</template>

<script>
import { Com } from '@/util'
import { required, email } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import BtnHome from '@/components/btn/Home'
import Adslot from '@/components/Adslot.vue'
import { theme } from '@/mixins'

export default {
  name: 'Contact',
  components: { BtnHome, Adslot },
  mixins: [validationMixin, theme],
  data: () => ({
    email: '',
    name: '',
    subject: '',
    message: '',
    messageSent: false,
    disableFormElements: false,
  }),
  validations: {
    email: { required, email },
    name: { required },
    subject: { required },
    message: { required },
  },
  computed: {
    emailErrors() {
      const errors = []
      if (!this.$v.email.$dirty) return errors
      !this.$v.email.email && errors.push(this.$t('views.contact.error_email_not_valid'))
      !this.$v.email.required && errors.push(this.$t('views.contact.error_email_required'))
      return errors
    },
    nameErrors() {
      const errors = []
      if (!this.$v.name.$dirty) return errors
      if (!this.$v.name.required) errors.push(this.$t('views.contact.error_name_required'))
      return errors
    },
    subjectErrors() {
      const errors = []
      if (!this.$v.subject.$dirty) return errors
      !this.$v.subject.required && errors.push(this.$t('views.contact.error_subject_required'))
      return errors
    },
    messageErrors() {
      const errors = []
      if (!this.$v.message.$dirty) return errors
      if (!this.$v.message.required) errors.push(this.$t('views.contact.error_message_required'))
      return errors
    },
  },
  methods: {
    async submit() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.disableFormElements = true
        const com = new Com()
        await com
          .sendContact({
            name: this.name,
            email: this.email,
            subject: this.subject,
            message: this.message,
          })
          .then(() => {
            this.messageSent = true
          })
      }
    },
    emailInput() {
      this.$v.email.$reset()
    },
    messageInput() {
      this.$v.message.$reset()
    },
    nameInput() {
      this.$v.name.$reset()
    },
    subjectInput() {
      this.$v.subject.$reset()
    },
  },
}
</script>

<style lang="scss"></style>
